import React from "react";
import "../Assets/Styles/Home.css";
import { useHistory } from "react-router-dom";
import { FaSimCard, FaCalculator } from "react-icons/fa";
function Home() {
  let history = useHistory();
  const GotoPath = () => {
    history.push("/Services");
  };
  return (
    <div className="mainblock">
      <div className="firstblock">
        <div className="FB-Content">
          <div className="FB-Info">
            <div className="FB-Info-title">Varaneek Technologies Pvt. Ltd</div>
            <div className="FB-Info-desc">
              Varaneek Technologies wants to be a dependable world-class
              organization. We shall imbibe the spirit of entrepreneurship
              within each individual throughout the organization. We shall
              strive to achieve Quality Services by comprehending their need
              through close interaction and by creating a global network.
            </div>
            <div className="FB-Info-button">
              <button onClick={GotoPath}>Services</button>
            </div>
          </div>
        </div>
      </div>
      <div className="secondblock">
        <div className="SB-Content">
          <div className="SB-Info-title">We Provide</div>
          <div className="SB-Block">
            <div className="SB-Blocks">
              <div className="SB-Icon">
                <FaCalculator />
              </div>
              <div className="SB-Info">
                <div className="SB-Title">mPOS</div>
                <div className="SB-Desc">
                  Mobile POS is a compact hand held device, paired with your
                  smart phone using Bluetooth helps you accept card payments
                  easily.
                </div>
              </div>
            </div>
            <div className="SB-Blocks">
              <div className="SB-Icon">
                <FaSimCard />
              </div>
              <div className="SB-Info">
                <div className="SB-Title">GPRS/Digital POS</div>
                <div className="SB-Desc">
                  A next generation POS, allows you to accept card payments
                  seamlessly, apt for high volume of transactions. Eliminates
                  the hassles of paper roll & receipts.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="thirdblock">
        <div className="TB-Content">
          <div className="SB-Info-title">Industries We Collaborated With</div>
          <div className="TB-Info">
            <div className="TB-blocks">
              <img
                src={require("../Assets/Imgs/cabs.jpg")}
                alt="img"
                className="TB-Imgs"
              />
              <div
                className="TB-displayBack"
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom, rgba(255, 250, 235, 0.2),rgba(255, 191, 0,0.89))",
                }}
              ></div>
              <div className="TB-displayText">Transport</div>
            </div>
            <div className="TB-blocks">
              <img
                src={require("../Assets/Imgs/bevrage.jpg")}
                alt="img"
                className="TB-Imgs"
              />
              <div
                className="TB-displayBack"
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom, rgba(255, 250, 235, 0.2),rgba(74, 74, 74,1))",
                }}
              ></div>
              <div className="TB-displayText">Beverage</div>
            </div>
            <div className="TB-blocks">
              <img
                src={require("../Assets/Imgs/clinic.jpg")}
                alt="img"
                className="TB-Imgs"
              />
              <div
                className="TB-displayBack"
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom, rgba(255, 250, 235, 0.2),rgba(67, 98, 210, 0.92))",
                }}
              ></div>
              <div className="TB-displayText">Health Care</div>
            </div>

            <div className="TB-blocks">
              <img
                src={require("../Assets/Imgs/cooking.jpg")}
                alt="img"
                className="TB-Imgs"
              />
              <div
                className="TB-displayBack"
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom, rgba(255, 250, 235, 0.2),rgba(247, 90, 90, 0.92))",
                }}
              ></div>
              <div className="TB-displayText">Restaurants</div>
            </div>
            <div className="TB-blocks">
              <img
                src={require("../Assets/Imgs/resorts.jpg")}
                alt="img"
                className="TB-Imgs"
              />
              <div
                className="TB-displayBack"
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom, rgba(255, 250, 235, 0.2),rgba(169, 29, 171, 0.92))",
                }}
              ></div>
              <div className="TB-displayText">Hotels</div>
            </div>
            <div className="TB-blocks">
              <img
                src={require("../Assets/Imgs/shopping.jpeg")}
                alt="img"
                className="TB-Imgs"
              />
              <div
                className="TB-displayBack"
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom, rgba(255, 250, 235, 0.2),rgba(27, 113, 29, 0.92))",
                }}
              ></div>
              <div className="TB-displayText">Stores</div>
            </div>
          </div>
        </div>
      </div>
      <div className="forthblock">
        <div className="TB-Content">
          <div className="SB-Info-title">Our Strategic Partners</div>
          <div className="FthB-Info">
            <div className="TB-blocks">
              <img
                src={require("../Assets/Imgs/bijilipay.png")}
                alt="img"
                className="FthB-Imgs"
              />
            </div>
            <div className="TB-blocks">
              <img
                src={require("../Assets/Imgs/axis.png")}
                alt="img"
                className="FthB-Imgs"
              />
            </div>
            <div className="TB-blocks">
              <img
                src={require("../Assets/Imgs/Worldline.jpeg")}
                alt="img"
                className="FthB-Imgs"
              />
            </div>
            <div className="TB-blocks">
              <img
                src={require("../Assets/Imgs/rbl.png")}
                alt="img"
                className="FthB-Imgs"
              />
            </div>
            <div className="TB-blocks">
              <img
                src={require("../Assets/Imgs/UBI.jpeg")}
                alt="img"
                className="FthB-Imgs"
              />
            </div>
          </div>

          <div className="SB-Info-title">We Accept</div>
          <div className="FthB-Info">
            <div className="TB-blocks">
              <img
                src={require("../Assets/Imgs/master.png")}
                alt="img"
                className="FthB-Imgs"
              />
            </div>
            <div className="TB-blocks">
              <img
                src={require("../Assets/Imgs/visa.png")}
                alt="img"
                className="FthB-Imgs"
              />
            </div>
            <div className="TB-blocks">
              <img
                src={require("../Assets/Imgs/Rupay.png")}
                alt="img"
                className="FthB-Imgs"
              />
            </div>
            <div className="TB-blocks">
              <img
                src={require("../Assets/Imgs/sodexo.png")}
                alt="img"
                className="FthB-Imgs"
              />
            </div>
            <div className="TB-blocks">
              <img
                src={require("../Assets/Imgs/amex.png")}
                alt="img"
                className="FthB-Imgs"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
