import React from "react";
import { FaSimCard,FaCalculator,FaMobile,FaShoppingCart } from 'react-icons/fa';
function Serv() {
	return (
		<div>
			<div className="secondblock">
				<div className="SB-Content">
					<div className="SB-Info-title">We Provide</div>
					<div className="SB-Block">
						<div className="SB-Blocks">
							<div className="SB-Icon">
								<FaCalculator />
							</div>
							<div className="SB-Info">
								<div className="SB-Title">mPOS</div>
								<div className="SB-Desc">
									Mobile POS is a compact hand held device,
									paired with your smart phone using Bluetooth
									helps you accept card payments easily.
								</div>
							</div>
						</div>
						<div className="SB-Blocks">
							<div className="SB-Icon">
								<FaSimCard />
							</div>
							<div className="SB-Info">
								<div className="SB-Title">GPRS/Digital POS</div>
								<div className="SB-Desc">
									A next generation POS, allows you to accept
									card payments seamlessly, apt for high
									volume of transactions. Eliminates the
									hassles of paper roll & receipts.
								</div>
							</div>
						</div>
							<div className="SB-Blocks">
							<div className="SB-Icon">
								<FaMobile />
							</div>
							<div className="SB-Info">
								<div className="SB-Title">Mobile App Payments</div>
								<div className="SB-Desc">
									Coming Soon
								</div>
							</div>
						</div>
							<div className="SB-Blocks">
							<div className="SB-Icon">
								<FaShoppingCart />
							</div>
							<div className="SB-Info">
								<div className="SB-Title">E-Commerce</div>
								<div className="SB-Desc">
									Coming Soon
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Serv;
