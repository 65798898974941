import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const axios = require('axios');
function Contact() {
    const [name, setName] = useState("");
    const [add, setAdd] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [msg, setMsg] = useState("");

    const submit = event => {
        var check = 1, err = 'Form cannot be empty';
        if (!name) {
            check = 0; err = 'Name cannot be empty';
        }
        if (!add) {
            check = 0; err = 'Address cannot be empty';
        }
        if (!mobile || mobile.length != 10) {
            check = 0; err = 'Mobile cannot be empty or less then 10 numbers';
        }
        if (!email) {
            check = 0; err = 'Email cannot be empty';
        }
        if (!msg) {
            check = 0; err = 'Message cannot be empty';
        }

        if (check) {


            axios({
                method: 'post',
                url: 'https://www.varaneektech.com/enquirysend.php',

                data: "save=submit&name=" + name + "&place=" + add + "&phone=" + mobile + "&mail=" + email + "&message=" + msg
            }).then(function (response) {
                // handle success
                if (response.data.data == 'success') {
                    toast('Successfully submitted', {
                        position: toast.POSITION.BOTTOM_CENTER,
                        type: toast.TYPE.SUCCESS
                    });
                    setName(''); setAdd(''); setMobile(''); setEmail(''); setMsg('');
                } else {
                    toast("Something went wrong. Try after some time", {
                        position: toast.POSITION.BOTTOM_CENTER,
                        type: toast.TYPE.ERROR
                    });
                }
            })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })

        } else {
            toast(err, {
                position: toast.POSITION.BOTTOM_CENTER,
                type: toast.TYPE.ERROR
            });
        }

    };

    return (
        <div className='formContainer'>
            <div className='formTitle'>Contact US</div>
            <div className='inputContainer'>
                <label>Full Name</label><br />
                <input
                    placeholder="Name"
                    value={name}
                    onChange={val => {
                        setName(val.target.value);
                    }}
                />
            </div>
            <div className='inputContainer'>
                <label>Place</label><br />
                <input
                    placeholder="Address"
                    value={add}
                    onChange={val => {
                        setAdd(val.target.value);
                    }}
                />
            </div>
            <div className='inputContainer'>
                <label>Mobile Number</label><br />
                <input
                    type="number"
                    placeholder="Mobile Number"
                    value={mobile}
                    onChange={val => {
                        setMobile(val.target.value);
                    }}
                />
            </div>
            <div className='inputContainer'>
                <label>Email</label><br />
                <input
                    type="text"
                    placeholder="Email"
                    value={email}
                    onChange={val => {
                        setEmail(val.target.value);
                    }}
                />
            </div>
            <div className='inputContainer'>
                <label>Message</label><br />
                <textarea

                    placeholder="Message"
                    value={msg}
                    onChange={val => {
                        setMsg(val.target.value);
                    }}
                />
            </div>
            <button className="formSubmit" type="submit" onClick={submit}>
                Submit
            </button>
            <ToastContainer />
        </div>
    );
}

export default Contact;
