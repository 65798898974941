import React from "react";
import '../Assets/Styles/Vendors.css';

function Vendors() {
    return (
        <div className='TB-Content'>
            <div className='SB-Info-title'>Industries We Collaborated With</div>
            <div className='Ven-Info'>
                <div className='Ven-Blocks'>
                    <div className='Ven-Imgs'>
                        <img src={require('../Assets/Imgs/cabs.jpg')} alt="img" className='TB-Imgs' />
                        <div className='TB-displayBack' style={{ backgroundImage: 'linear-gradient(to bottom, rgba(255, 250, 235, 0.2),rgba(255, 191, 0,0.89))' }}></div>
                        <div className='TB-displayText'>Transport</div>
                    </div>
                    <div className='Ven-Name'>
                        <p>Sri Ayyappa Tours and Travels</p>
                        <p>True Cab Services</p>
                        <p>And more...</p>
                    </div>
                </div>

                <div className='Ven-Blocks'>
                    <div className='Ven-Imgs'>
                        <img src={require('../Assets/Imgs/bevrage.jpg')} alt="img" className='TB-Imgs' />
                        <div className='TB-displayBack' style={{ backgroundImage: 'linear-gradient(to bottom, rgba(255, 250, 235, 0.2),rgba(74, 74, 74,1))' }}></div>
                        <div className='TB-displayText'>Beverage</div>
                    </div>
                    <div className='Ven-Name'>
                        <p>Durga Wines</p>
                        <p>Sri Sai Wines</p>
                        <p>Protein Shakes</p>
                        <p>And more...</p>

                    </div>
                </div>

                <div className='Ven-Blocks'>
                    <div className='Ven-Imgs'>
                        <img src={require('../Assets/Imgs/clinic.jpg')} alt="img" className='TB-Imgs' />
                        <div className='TB-displayBack' style={{ backgroundImage: 'linear-gradient(to bottom, rgba(255, 250, 235, 0.2),rgba(67, 98, 210, 0.92))' }}></div>
                        <div className='TB-displayText'>Health Care</div>
                    </div>
                    <div className='Ven-Name'>
                        <p>Heartfulness Meditation</p>
                        <p>Neeta Rastogi Family Practice (Dr.)</p>
                        <p>Pinnacle Health System</p>
                        <p>Smiley Dentistry</p>
                        <p>Schein Ernst Mishra Eye</p>
                        <p>And more...</p>
                    </div>
                </div>


                <div className='Ven-Blocks'>
                    <div className='Ven-Imgs'>
                        <img src={require('../Assets/Imgs/cooking.jpg')} alt="img" className='TB-Imgs' />
                        <div className='TB-displayBack' style={{ backgroundImage: 'linear-gradient(to bottom, rgba(255, 250, 235, 0.2),rgba(247, 90, 90, 0.92))' }}></div>
                        <div className='TB-displayText'>Restaurants</div>
                    </div>
                    <div className='Ven-Name'>
                        <p>Papakura Resturants</p>
                        <p>Speed Six Resturantes</p>
                        <p>Navayuga Resturantes</p>
                        <p>Santhosh Family Dhaba</p>
                        <p>Thirumala Bakery</p>
                        <p>And more...</p>

                    </div>
                </div>


                <div className='Ven-Blocks'>
                    <div className='Ven-Imgs'>
                        <img src={require('../Assets/Imgs/resorts.jpg')} alt="img" className='TB-Imgs' />
                        <div className='TB-displayBack' style={{ backgroundImage: 'linear-gradient(to bottom, rgba(255, 250, 235, 0.2),rgba(169, 29, 171, 0.92))' }}></div>
                        <div className='TB-displayText'>Hotels</div>
                    </div>
                    <div className='Ven-Name'>
                        <p>Hershey Entertainment & Resorts Company</p>
                        <p>Nascal Pub</p>
                        <p>Ice Angel Pub</p>
                        <p>Turning Point Pub</p>
                        <p>Bounty Queen Pub</p>


                        <p>And more...</p>
                    </div>
                </div>


                <div className='Ven-Blocks'>
                    <div className='Ven-Imgs'>
                        <img src={require('../Assets/Imgs/shopping.jpeg')} alt="img" className='TB-Imgs' />
                        <div className='TB-displayBack' style={{ backgroundImage: 'linear-gradient(to bottom, rgba(255, 250, 235, 0.2),rgba(27, 113, 29, 0.92))' }}></div>
                        <div className='TB-displayText'>Stores</div>
                    </div>
                    <div className='Ven-Name'>
                        <p>Decorating Den Interiors</p>
                        <p>Sony Electronics</p>
                        <p>Sri Prigal Enterprises</p>
                        <p>Anika Indian Wear</p>
                        <p>Krishna Furnitures</p>
                        <p>And more...</p>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Vendors;