import React from "react";
import "./Assets/Styles/App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from './Screens/Home';
import Vendors from './Screens/Vendors';
import Franchise from './Screens/Franchise';
import Contact from './Screens/Contact';
import Header from './Screens/Common/Header';
import Footer from './Screens/Common/Footer';
import Serv from './Screens/Serv';
import PageNotFound from './Screens/PageNotFound'
function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <div style={{ paddingTop: 90 }}>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/Home" component={Home} />
            <Route path="/Vendors" component={Vendors} />
            <Route path="/Franchise" component={Franchise} />
            <Route path="/Contact" component={Contact} />
            <Route path="/Services" component={Serv} />
            <Route path="*" component={PageNotFound} />
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
}
export default App;