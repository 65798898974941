import React from "react";
import { useHistory } from "react-router-dom";

function Franchise() {
	let history = useHistory();
	 const GotoPath = () =>{ history.push("/Contact"); }
  return (
    <div className='TB-Content' style={{height:500}}>
      
      <div className='SB-Info-title'>Franchise</div>
      <div className='FB-Info-desc'>We will provide comprehensive guidance and support to its franchisee, right from day one to ensure mutual growth and profitability. We accept franchises from all over india.</div>
      <div className='FB-Info-button'>
                <button onClick={GotoPath}>Contact Us</button>
              </div>
    </div>
  );
}

export default Franchise;