import React from "react";


function PageNotFound() {
  return (
    <div className='PNF-Container'>
    	<div className='PNF-INfo'>
      		<div className='PNF-tile'>404</div>
      		<div className='PNF-desc'>Page Not Found</div>
      	</div>
    </div>
  );
}
export default PageNotFound;